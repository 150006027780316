<template>
	<div class="activity-page">
		<no-data v-if="activity_list.length==0"></no-data>
		<van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" v-else>
			<van-list finished-text="加载完毕" :finished="finished" v-model="isloading" @load="loadData">
				
				<div v-for="(item,index) in activity_list" :key="index" @click="toActivity(item.id)" >
					<div class="my-list-item">
						<van-image class="image" :src="item.banner_images ? item.banner_images[0] : ''" fit="cover"></van-image>
						<div class="content">
							<div class="title">{{item.activity_name?item.activity_name:'空标题'}}</div>
							<div class="tip" v-if="item.is_show_person_switch">
								<span class="text-theme2" v-if="item.activitycategory">行业：{{item.activitycategory.category_name}}   </span>
								已报名:
								<span class="text-theme2">{{item.tickets_Count?item.tickets_Count:0}}</span>
							</div>
						</div>
						<div class="state">
							<span class="status bg-disabled" v-if="item.activity_status==0">未发布</span>
							<span class="status bg-theme4-color" v-if="item.activity_status==1">进行中</span>
						</div>
					</div>
					<div class="my-time">
						活动时间：{{item.start_time+'至'+item.end_time}}
					</div>
				</div>
				<div style="padding-bottom: 60px;"></div>

			</van-list>
		</van-pull-refresh>
		
	</div>
</template>
<script>
	import {
		List,
		Row,
		Col,
		Panel,
		PullRefresh,
		Sticky,
		Grid,
		GridItem,
		Image
	} from "vant";
	import {
		activity_list,
		index_getlistbycategory,
		index_getlistbycity
	} from "@/request/api";

	export default {
		components: {
			[List.name]: List,
			[Row.name]: Row,
			[Col.name]: Col,
			[Panel.name]: Panel,
			[PullRefresh.name]: PullRefresh,
			[Sticky.name]: Sticky,
			[Grid .name]: Grid,
			[GridItem .name]: GridItem,
			[Image.name]: Image
		},
		watch: {},
		data() {
			return {
				current_page: 0,
				has_more: true,
				activity_list: [],
				isLoading: false, //向上加载
				isloading: false, //向下加载
				finished: false,
				is_sponsor: false, //主办方活动管理列表
				day_type: 0,

				cityname: '定位中',
				ca_id: '',
				ca_page: 1
			};
		},
		mounted: function() {
			this.ca_id = this.$route.query.id
			this.loadData();
		},
		methods: {
			initData() {
				this.has_more = true;
				this.finished = false;
				this.current_page = 0;
				this.ca_page = 1;
				this.activity_list = [];

			},
			//活动列表  
			loadData() {

				//1、判断是否有更多 
				if (this.finished) {
					return;
				}
				//2、显示加载状态
				this.isloading = true;

				if (this.ca_id) {
					// 行业类型 列表
					index_getlistbycategory({
							id: this.ca_id,
							page: this.ca_page,
						})
						.then(res => {
							if (res.code == 1) {
								//4、判断上下加载
								if (this.isLoading) {
									this.isLoading = false;
									this.hideLoading();
									this.activity_list = res.data.data;
								} else {
									this.activity_list = this.activity_list.concat(res.data.data);
								}
								//5、关闭加载状态 判断是否加载完成 
								this.isloading = false;
								this.has_more = res.data.has_more;
								if (res.data.has_more) {
									this.ca_page++
								} else {
									this.finished = true;
								}
							} else {
								this.$dialog({
									message: res.msg
								});
							}
						})
						.catch(err => {
							this.$dialog({
								message: "获取活动数据有误，请刷新页面"
							});
						});
				} else {
					index_getlistbycity({
								page: this.current_page + 1,
								cityname: this.cityname
							})
							.then(res => {
								if (res.code == 1) {
					
									//4、判断上下加载
									if (this.isLoading) {
										this.isLoading = false;
										this.hideLoading();
										this.activity_list = res.data.data;
									} else {
										this.activity_list = this.activity_list.concat(res.data.data);
									}
					
									//5、关闭加载状态 判断是否加载完成 
									this.isloading = false;
									this.current_page = res.data.current_page;
									this.has_more = res.data.has_more;
									if (!res.data.has_more) {
										this.finished = true;
									}
								} else {
									this.$dialog({
										message: res.msg
									});
								}
							})
							.catch(err => {
								this.$dialog({
									message: "获取活动数据有误，请刷新页面"
								});
							});
				}
			},

			onRefresh() {
				//初始化状态
				this.isLoading = true;
				this.has_more = true;
				this.finished = false;
				this.current_page = 0;
				this.ca_page = 1;
				this.showLoading('刷新中', true);
				this.loadData();
			},
			
			toActivity(id){
				this.$router.push({
					path: '/activity',
					query: {
						id: id
					}
				})
			},
			getAddress(data) {
			  this.cityname = data.city;
			  this.loadData();
			},
		}
	};
</script>
<style lang="less">
	.activity-page {
		.van-pull-refresh {
			margin-top: 5px;
		}

	}
	
	.my-list-item{
		display: flex;
		align-items: center;
		padding: 10px 10px;
		padding-bottom: 0;
		background-color: white;
		.image{
			width: 130px;height: 94px;margin-right: 3px;
		}
		.content{
			width: 165px;
			height: 94px;
			padding-left: 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.title{
				font-size: 16px;
				width: 100%;
				height: 70px;
				opacity: hidden;
				text-overflow: ellipsis;
			}
			.tip{
				white-space: nowrap;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 14px;
			}
		}
		.state{
			.status{
				color: white;
				font-size: 14px;
				padding: 5px;
				border-radius: 5px;
			}
		}
	}
	.my-time{
		background-color: white;
		font-size: 12px;
		color: #888;
		padding: 10px;
		border-bottom: 1px solid #f2f2f2;
	}
</style>
